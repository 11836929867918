import React from "react";
import { Link } from "react-router-dom";

import { mobileMenu } from "../../../utils";
import { flexMatrix, solutions, sales, contact, about, account } from "./navData";
import { connect } from "react-redux";
import { getProductsMenu } from "../../../actions/products";

function MobileMainNav(props) {
  const PUBLIC_URL = "/react/molla";

  React.useEffect(() => {
    mobileMenu();
  });

  React.useEffect(() => {
    props.productsMenu.length === 0 && props.getProductsMenu();
  }, []);

  const renderProducts = () => {
    return props.productsMenu.map((product, index) => (
      <li key={index}>
        <Link to={`${process.env.PUBLIC_URL}${product.path}`} className="sf-with-ul">
          {product.category}
        </Link>

        <ul>
          {product.subcategory.map((category, index) => (
            <li key={index}>
              <Link to={`${process.env.PUBLIC_URL}${category.path}`}>{category.name}</Link>
            </li>
          ))}
        </ul>
      </li>
    ));
  };

  const renderFlexMatrix = () => {
    return flexMatrix[0].subcategory.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderSolutions = () => {
    return solutions.map((item) => (
      <li key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderSales = () => {
    return sales.map((item) => (
      <li key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderContact = () => {
    return contact.map((item) => (
      <li key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderAbout = () => {
    return about.map((item) => (
      <li key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderAccount = () => {
    return account.map((item) => (
      <li key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  return (
    <nav className="mobile-nav">
      <ul className="mobile-menu">
        {/* <li>
          <Link to="/flexmatrix">Flex Matrix</Link>
          <ul>{renderFlexMatrix()}</ul>
        </li> */}

        <li>
          <Link to="/cables">Products</Link>
          <ul>{renderProducts()}</ul>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/applications/classroom`}>Solutions</Link>
          <ul>{renderSolutions()}</ul>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/casestudy`}>Sales</Link>
          <ul>{renderSales()}</ul>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/contact`}>Contact</Link>
          <ul>{renderContact()}</ul>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/company`}>About</Link>
          <ul>{renderAbout()}</ul>
        </li>

        {props.auth.isAuthenticated && (
          <li>
            <Link to={`${process.env.PUBLIC_URL}/account`}>Account</Link>
            <ul>{renderAccount()}</ul>
          </li>
        )}
        <li>
          <Link to={`${process.env.PUBLIC_URL}/new`}>New Products</Link>
        </li>
      </ul>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getProductsMenu })(MobileMainNav);
