export const products = [
  {
    category: "Audio",
    path: "/audio",
    subcategory: [
      { name: "Amplifier", path: "/audio/amplifier" },
      { name: "Dante", path: "/audio/dante" },
      { name: "Decoder", path: "/audio/decoder" },
      { name: "Extender", path: "/audio/extender" },
      { name: "Extractor", path: "/audio/extractor" },
    ],
  },
  {
    category: "Cables",
    path: "/cables",
    subcategory: [
      { name: "DisplayPort", path: "/cables/displayport" },
      { name: "HDMI", path: "/cables/hdmi" },
      { name: "USB-C", path: "/cables/usb-c" },
    ],
  },
  {
    category: "Controls",
    path: "/controls",
    subcategory: [
      { name: "Accessories", path: "/controls/accessories" },
      { name: "Extenders", path: "/controls/extenders" },
      { name: "Processor", path: "/controls/processor" },
    ],
  },
  {
    category: "Extenders",
    path: "/extenders",
    subcategory: [
      { name: "HDMI", path: "/extenders/hdmi" },
      { name: "HDMI Pro", path: "/extenders/hdmi-pro" },
      { name: "NetworkAV", path: "/extenders/networkav" },
      { name: "USB", path: "/extenders/usb" },
      { name: "Wall Plates", path: "/extenders/wall-plates" },
    ],
  },
  {
    category: "Flex Matrix",
    path: "/flex-matrix",
    subcategory: [
      { name: "Flex Chassis", path: "/flex-matrix/flex-chassis" },
      { name: "Input Cards", path: "/flex-matrix/input-cards" },
      { name: "Output Cards", path: "/flex-matrix/output-cards" },
    ],
  },
  {
    category: "FlexPro Matrix",
    path: "/flexpro-matrix",
    subcategory: [
      { name: "Flex Chassis", path: "/flexpro-matrix/flex-chassis" },
      { name: "Input Cards", path: "/flexpro-matrix/input-cards" },
      { name: "Output Cards", path: "/flexpro-matrix/output-cards" },
      { name: "Xtenders", path: "/flexpro-matrix/xtenders" },
    ],
  },
  {
    category: "Matrix",
    path: "/matrix",
    subcategory: [
      { name: "Matrix", path: "/matrix/matrix" },
      { name: "Matrix Pro", path: "/matrix/matrix-pro" },
    ],
  },
  {
    category: "SDI",
    path: "/sdi",
    subcategory: [
      { name: "Converters", path: "/sdi/converters" },
      { name: "Extenders", path: "/sdi/extenders" },
    ],
  },
  {
    category: "Splitters",
    path: "/splitters",
    subcategory: [
      { name: "HDMI", path: "/splitters/hdmi" },
      { name: "HDMI Pro", path: "/splitters/hdmi-pro" },
      { name: "MultiView", path: "/splitters/multiview" },
    ],
  },
  {
    category: "Switchers",
    path: "/switchers",
    subcategory: [
      { name: "HDMI", path: "/switchers/hdmi" },
      { name: "KVM", path: "/switchers/kvm" },
      { name: "Scalers", path: "/switchers/scalers" },
    ],
  },
];


export const solutions = [
  {
    name: "Broadcast",
    path: "/applications/broadcast",
  },
  {
    name: "Classroom",
    path: "/applications/classroom",
  },
  {
    name: "Conference Rooms",
    path: "/applications/corporate",
  },
  {
    name: "Digital Signage",
    path: "/applications/signage",
  },
  {
    name: "Government",
    path: "/applications/government",
  },
  {
    name: "Hospitality",
    path: "/applications/hospitality",
  },
  {
    name: "House of Worship",
    path: "/applications/worship",
  },
  {
    name: "Residential",
    path: "/applications/residential",
  },
];

export const sales = [
  {
    name: "Sales Inquiries",
    path: "/sales",
  },
  {
    name: "Press Inquiries",
    path: "/press",
  },
];

export const contact = [
  {
    name: "Dealer's Signup",
    path: "/contact",
  },
  // {
  //   name: "Inquire Product",
  //   path: "/inquireproduct",
  // },
  // {
  //   name: "Newsletter Signup",
  //   path: "/signup",
  // },

  {
    name: "Tech Support",
    path: "/support",
  },
];

export const about = [
  {
    name: "Company",
    path: "/company",
  },
  {
    name: "Catalog",
    path: "/catalog",
  },
  {
    name: "News",
    path: "/media",
  },
  {
    name: "Blogs",
    path: "/blog",
  },
  {
    name: "Partners",
    path: "/partners",
  },
  {
    name: "Terms",
    path: "/company/terms",
  },
];

export const account = [
  {
    name: "My Info",
    path: "/account/info",
  },
  {
    name: "Order history",
    path: "/account/orders",
  },

  {
    name: "Open Items",
    path: "/account/open",
  },

  {
    name: "Product List",
    path: "/account/skulist",
  },
  {
    name: "Shipments",
    path: "/account",
  },

  {
    name: "Sku History",
    path: "/account/history",
  },
  // {
  //   name: "Change Password",
  //   path: "/account/password",
  // },
  {
    name: "Logout",
    path: "/logout",
  },
];

export const resource = [
  {
    name: "CA Prop 65",
    path: "/warranty/CAProp65",
  },
  {
    name: "Case Studies",
    path: "/casestudy",
  },

  {
    name: "FAQ",
    path: "/faq",
  },
  {
    name: "Warranty",
    path: "/warranty",
  },
  {
    name: "Flex Matrix",
    path: "/flexmatrix",
  },
];

export const flexMatrix = [
  {
    name: "Flex Matrix",
    path: "/flexmatrix",
    subcategory: [
      { name: "Enterprise", path: "/flexmatrix/enterprise" },
      { name: "Government", path: "/flexmatrix/government" },
      { name: "Hospitality", path: "/flexmatrix/restaurant" },
      { name: "Higher Education", path: "/flexmatrix/higherEducation" },
      { name: "Entertainment", path: "/flexmatrix/entertainment" },
      { name: "Financial", path: "/flexmatrix/financial" }
    ],
  },
];
