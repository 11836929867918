import React from "react";
import { Route, Switch } from "react-router-dom";
import AccountInfo from "../components/account/info/index";

import Layout from "../components/app";

import Classroom from "../components/solutions";
import Solutions from "../components/solutions";
import Cart from "../components/cart";
import ScrollToTop from "../components/common/ScrollToTop";
import HomePage from "../components/home";
import comingSoon from "../components/pages/others/coming-soon";
import Product from "../components/product";
import CaseStudies from "../components/resources/case-studies";
import Category from "../components/products/Category";
import SubCategory from "../components/products/SubCategory";
import Faq from "../components/resources/faq";
import Shipments from "../components/account/shipments";
import About_us from "../components/About_us";
import TechSupport from "../components/forms/TechSupport";
import Warranty from "../components/Warranty";
import CA65 from "../components/CA65";
import KanexPartner from "../components/KanexPartner";
import SaleInquiryPage from "../components/forms/SaleInquiry";
import Orders from "../components/account/orders";
import OrderDetails from "../components/account/order-details";
import InquireProduct from "../components/product/InquireProduct";
import Checkout from "../components/checkout";
import OpenItems from "../components/account/open-items";
import ProductList from "../components/account/product-list";
import SkuHistory from "../components/account/sku-history";
import ChangePassword from "../components/account/change-password";
import ReviewOrder from "../components/account/review-order";

import PressInquiry from "../components/forms/PressInquiry";
import NewsLetter from "../components/forms/NewsLetter";
import DealerSign from "../components/forms/DealerSignUp";
import TermsPage from "../components/TermsPage";
import NewsMedia from "../components/NewsMedia";
import MediaDescription from "../components/NewsMedia/MediaDescription";
import Blog from "../components/Blogs";
import BlogDesc from "../components/Blogs/BlogDesc/index";
import Invoices from "../components/account/invoices";
import Avsolution from "../components/AvSolution";
import WorkFromHome from "../components/WorkFromHome";
import { connect } from "react-redux";
import { userLogout } from "../actions/auth";
import { emptyCart } from "../actions/products";
import PrivateRoute from "./PrivateRoute";
import NewProducts from "../components/products/NewProducts";
import SearchProducts from "../components/products/SearchProducts";
import ImpersonateUser from "../components/Impersonateuser";
import FlexMatrix from "../components/FlexMatrix";
import CataLog from "../components/Catalog/catalog";
import KanexCatalog from "../components/Catalog";
import Enterprise from "../components/Enterprise";
import Government from "../components/Government";
import Restaurant from "../components/Restaurant";
import AocPage from "../components/AOCPage/aocPage";
import HigherEducation from "../components/Government/higherEducation";
import Financial from "../components/Government/financialPage";
import Entertainment from "../components/Government/enterainment";

function HomeRoute(props) {
  const { history, location, userLogout, match, emptyCart } = props;

  return (
    <ScrollToTop>
      <Switch>
        <Layout history={history}>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
          {/* Products */}
          <Route exact path={`${process.env.PUBLIC_URL}/allproducts`} component={HomePage} />
          <Route exact path={`${process.env.PUBLIC_URL}/new`} component={NewProducts} />
          <Route exact path={`${process.env.PUBLIC_URL}/search/:term/:page`} component={SearchProducts} />
          <Route exact path={`${process.env.PUBLIC_URL}/cables`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/cables/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/converters`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/converters/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/controls`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/controls/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/extenders`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/extenders/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/switchers`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/switchers/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/splitters`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/splitters/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/sdi`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/sdi/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/networkav`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/networkav/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/audio`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/audio/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/flex-matrix`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/flex-matrix/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexpro-matrix`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexpro-matrix/:subCategory`} component={SubCategory} />
          <Route exact path={`${process.env.PUBLIC_URL}/matrix`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/matrix/:subCategory`} component={SubCategory} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/item/`}
            component={() => {
              const itemId = location.search.replace("?id=", "");
              history.push(`/item/${itemId}`);
              return null;
            }}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/item/:productId`} component={Product} />

          <Route exact path={`${process.env.PUBLIC_URL}/inquireproduct`} component={InquireProduct} />
          <Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout} />
          {/* Solutions */}
          <Route exact path={`${process.env.PUBLIC_URL}/applications/:solutions`} component={Solutions} />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/applications/corporate`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/signage`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/government`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/residential`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/hospitality`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/worship`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/applications/broadcast`} component={comingSoon} /> */}
          {/* Resources */}
          <Route exact path={`${process.env.PUBLIC_URL}/resources`} component={comingSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/casestudy`} component={CaseStudies} />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
          <Route
            exact
            //path="/catalog"
            path={`${process.env.PUBLIC_URL}/catalog`}
            component={KanexCatalog}
          // component={() => {
          //   window.open("https://www.kanexpro.com/catalog/KanexPro_Catalog_AUG_2020_V1.pdf", "_blank");
          //   history.goBack();
          //   return null;
          // }}
          />
          {/* Contact */}
          <Route exact path={`${process.env.PUBLIC_URL}/support`} component={TechSupport} />
          <Route exact path={`${process.env.PUBLIC_URL}/sales`} component={SaleInquiryPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/press`} component={PressInquiry} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={DealerSign} />
          <Route exact path={`${process.env.PUBLIC_URL}/warranty`} component={Warranty} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix`} component={FlexMatrix} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/enterprise`} component={Enterprise} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/government`} component={Government} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/restaurant`} component={Restaurant} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/higherEducation`} component={HigherEducation} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/financial`} component={Financial} />
          <Route exact path={`${process.env.PUBLIC_URL}/flexmatrix/entertainment`} component={Entertainment} />
          <Route exact path={`${process.env.PUBLIC_URL}/aoc`} component={AocPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/warranty/CAProp65`} component={CA65} />
          <Route exact path={`${process.env.PUBLIC_URL}/signup`} component={NewsLetter} />
          <Route exact path={`${process.env.PUBLIC_URL}/work_from_home`} component={WorkFromHome} />
          {/* About */}
          <Route exact path={`${process.env.PUBLIC_URL}/company`} component={About_us} />
          <Route exact path={`${process.env.PUBLIC_URL}/company/terms`} component={TermsPage} />

          <Route exact path={`${process.env.PUBLIC_URL}/partners`} component={KanexPartner} />
          <Route exact path={`${process.env.PUBLIC_URL}/media`} component={NewsMedia} />
          <Route exact path={`${process.env.PUBLIC_URL}/media/article/:Id`} component={MediaDescription} />

          <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/description/:Id`} component={BlogDesc} />


          {/* Logged In */}
          <Route exact path={`${process.env.PUBLIC_URL}/account`} component={Shipments} />

          <Route exact path={`${process.env.PUBLIC_URL}/account/info`} component={AccountInfo} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/orders`} component={Orders} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/open`} component={OpenItems} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/invoices`} component={Invoices} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/skulist`} component={ProductList} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/history`} component={SkuHistory} />
          <Route exact path={`${process.env.PUBLIC_URL}/account/password`} component={ChangePassword} />

          <Route exact path={`${process.env.PUBLIC_URL}/account/order/:orderId`} component={OrderDetails} />

          <Route exact path={`${process.env.PUBLIC_URL}/secure/cart`} component={Cart} />
          <Route exact path={`${process.env.PUBLIC_URL}/secure/revieworder`} component={ReviewOrder} />
          <Route exact path={`${process.env.PUBLIC_URL}/avsolutions`} component={Avsolution} />
          <Route
            exact
            path="/logout"
            component={() => {
              userLogout();
              history.push("/");
              return null;
            }}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/imp/:userName`} component={ImpersonateUser} />
        </Layout>
      </Switch>
    </ScrollToTop>
  );
}

export default connect(null, { userLogout, emptyCart })(HomeRoute);
